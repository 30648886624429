/* Tailwind Base */
@tailwind base;
/* Tailwind Components */
@tailwind components;
/* Tailwind Utilities (leave it in the end) */
@tailwind utilities;

/* Helpers */

/* .h-96 {height: 24rem;} */

/* .h-128 {height: 32rem;} */

/* .w-128 {width: 32rem;} */

/* CSS Variables */

:root {
    --blue-900: #2a4365;
}

/* General */

html {
    scroll-behavior: smooth;
}

/* Tipography */

.text-dark {
    @apply text-blue-900;
}

.text-light {
    @apply text-gray-500;
}

h1, h2, h3, h4, h5 {
    @apply text-blue-900 font-title;
}

h1 {@apply text-3xl;}
h2 {@apply text-2xl;}
h3 {@apply text-xl;}
h4 {@apply text-lg;}

@screen sm {
    h1 {@apply text-5xl;}
}

.anim-under {position: relative;}

.anim-under:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -3px;
    left: 0;
    background-color: var(--blue-900);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.25s cubic-bezier(1, 0.25, 0, 0.75) 0s;
    border-radius: 10px;
}

.anim-under:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

/* Elements */

.btn {
    @apply bg-easing-r-grad1 inline-flex items-center text-white text-base border-0 py-1 px-3 mx-2 rounded;
}

.btn:hover {
    @apply bg-easing-r-grad1-hover;
}

.btn:focus {
    @apply outline-none;
}

.hero-img img {
    @apply rounded;
}

.bg-testimonials {
    @apply bg-easing-r-clouds;
}

